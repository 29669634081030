@import "src/common/styles/variables";

.container {
  padding: 10px 30px;

  .heading {
    margin-top: 15px;
    font-size: 1.2rem;
    font-weight: 600;
  }

  .content {
    padding: 10px 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 15px;
    font-size: 0.9rem;

    .card {
      border-radius: 8px;
      padding: 15px;
      background-color: $appWhite;

      .cardHeading {
        font-size: 1rem;
        font-weight: 600;
        text-align: center;
        margin-bottom: 5px;
      }

      .cardIcon {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
      }

      .description {
        font-style: italic;
        margin-bottom: 5px;
      }

      .day {
        font-weight: 600;
        margin-bottom: 5px;

        span {
          color: $appOrange500;
        }
      }

      .night {
        font-size: 0.85rem;
      }
    }
  }
}
