@import "../../common/styles/variables";

.container {
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  justify-content: space-between;
  height: $headerHeight;
  padding: 0 30px;
  border-bottom: 1px solid $appGray800;
}
