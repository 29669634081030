@import "src/common/styles/variables";

.container {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  width: 25%;

  .link {
    color: $appTextColor;
    text-decoration: none;
    outline: none;

    &__active {
      color: $appOrange500;
    }

    &__disabled {
      pointer-events: none;
      color: lighten($appTextColor, 50);
    }
  }
}
