@import "src/common/styles/variables";

.container {
  position: relative;
  display: flex;
  width: 150px;
  height: 150px;
  padding: 15px;
  overflow: hidden;

  appearance: none;
  color: inherit;
  font-family: inherit;
  text-align: inherit;
  border: 1px solid $appGray800;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    outline: none;
    box-shadow: 0 0 0 2px lighten($appButtonBgColor, 25%);
  }

  &__active {
    border-color: $appActiveBorderColor;
    border-width: 2px;
  }

  &[disabled] {
    cursor: not-allowed;
    box-shadow: inherit;
    opacity: inherit;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 30px;
    background: linear-gradient(180deg, transparent, $appGray50 50%);
  }

  .content {
    .city {
      font-size: 1.2rem;
      font-weight: 600;
    }

    .stateCountry {
      font-size: 0.9rem;
      color: $appOrange400;
    }

    .address {
      font-size: 0.9rem;
      margin-top: 5px;
    }
  }
}
