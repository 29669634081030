@import "src/common/styles/variables";

.container {
  overflow: hidden;
  border: 2px solid $appGray800;
  border-radius: 8px;
  background-color: $appGray150;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.4);
  }
}
