@import "src/common/styles/variables";

.container {
  padding: 15px 30px;

  .heading {
    text-align: center;
    margin-bottom: 15px;
    font-size: 1.2rem;
    font-weight: 600;

    &__faded {
      font-size: 1rem;
      font-weight: 400;
      opacity: 0.5;
    }
  }

  .placeCards {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
}
