$headerHeight: 80px;
$footerHeight: 60px;

// Colors
$appBlack: #000;
$appWhite: #fff;
$appGray50: #f2f2f2;
$appGray100: #ececed;
$appGray150: #e6e6e6;
$appGray450: #8c8c8d;
$appGray500: #757575;
$appGray550: #6e6e6d;
$appGray800: #474648;
$appOrange400: #f86a51;
$appOrange500: #ff5e44;
$appTurquoise: #32cebf;

// Pallet
$appTextColor: $appGray800;
$appBgColor: $appGray50;
$appButtonTextColor: $appGray50;
$appButtonBgColor: $appGray450;
$appCloseButtonBgColor: $appOrange500;
$appActiveBorderColor: $appTurquoise;
$appBorderColor: $appGray800;
