@import "src/common/styles/variables";

.container {
  padding: 10px 30px;

  .heading {
    margin-top: 15px;
    font-size: 1.2rem;
    font-weight: 600;
  }

  .content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;

    .table {
      width: 35%;
      min-width: 400px;
      display: flex;
      flex-direction: column;
      font-size: 0.9rem;

      .table_heading {
        font-size: 1rem;
        font-weight: 600;
        border-bottom: 1px solid $appGray800;
      }

      &_heading,
      &_content {
        display: flex;
        padding: 15px 0;

        .leftCol {
          flex: 1 0 15%;
          display: flex;
          align-items: center;
        }

        .rightCol {
          flex: 0 0 85%;
          display: flex;
          align-items: center;

          .itemDescription {
            padding: 0 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;

            .description,
            .windSpeed {
              color: $appOrange400;
            }

            .windSpeed {
              color: $appTurquoise;
              font-style: italic;
            }
          }
        }
      }
    }

    .map {
      width: 60%;
      border: 1px solid $appGray800;
      border-radius: 8px;
      overflow: hidden;

      @media (max-width: 1100px) {
        width: 55%;
      }

      @media (max-width: 985px) {
        width: 50%;
      }

      @media (max-width: 890px) {
        width: 100%;
      }
    }
  }
}
