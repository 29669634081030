@import "../../styles/variables";

.container {
  flex: 1 0 auto;
  margin: 0 auto;
  padding-top: 200px;
  overflow: auto;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;

  .description {
    font-weight: 600;
    font-size: 1.2rem;
  }
}
